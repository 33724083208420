export const roleIds = [
  "ceo_founder_business_owner",
  "leader_manager",
  "entrepreneur",
  "software_engineer",
  "designer",
  "product_manager",
  "illustrator",
  "photographer",
  "filmmaker_vlogger",
  "blogger",
  "writer_editor",
  "marketer",
  "social_media_manager",
  "analyst",
  "trader",
  "artist",
  "musician",
  "student",
  "teacher",
  "researcher_scientist",
  "other",
];

export const roleLabels = [
  "CEO / Founder / Business Owner",
  "Leader / Manager",
  "Entrepreneur",
  "Software Engineer",
  "Designer",
  "Product Manager",
  "Illustrator",
  "Photographer",
  "Filmmaker / Vlogger",
  "Blogger",
  "Journalist, Writer or Editor",
  "Marketer / SEO Expert",
  "Social Media Manager",
  "Analyst",
  "Trader",
  "Artist",
  "Musician",
  "Student",
  "Teacher",
  "Researcher / Scientist",
  "Other",
];
